import React, { useState } from 'react';

function Contactus() {
    const [showThankYou, setShowThankYou] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({});
    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault()
        // console.log('submt', form, encode({
        //     "form-name": "contactus",
        //     ...form
        // }))
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": "contactus",
                ...form
            }),
        }).then((res) => {
            // console.log('res', res)
            setLoading(false);
            setShowThankYou(true);

            setTimeout(() => {
                setShowThankYou(false);
            }, 6000)
        }).catch((err) => {
            // console.log('err', err)
            setLoading(false);
        })
    }
    function encode(data) {
        return Object.keys(data)
            .map(
                (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
            )
            .join("&");
    }

    const handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        setForm({
            ...form,
            [name]: value,
        });
        // console.log({ form })
    }

    return (
        <section className="contact-container tw-flex tw-flex-col" id="contact_us">
            <h4>
                {'<CONTACT US />'}
            </h4>
            <form name="contactus" data-netlify="true" netlify netlify-honeypot="bot-field" hidden>
                <input type="text" name="name" />
                <input type="email" name="email" />
                <input type="text" name="data" />
                {/* <input type="text" name="Discord" /> */}
            </form>

            <div className='tw-flex tw-flex-col lg:tw-flex-row'>
                <div className='tw-flex contact-us-desc'>
                    <p>
                        We have no sales team or layers of management. Connect directly with the people who're going to bring
                        your ideas to life.
                    </p>
                </div>

                <form id="my-formcarry"
                    accept-charset="UTF-8"
                    className='tw-flex tw-flex-col'
                    onSubmit={handleSubmit}
                >

                    <fieldset
                        className='tw-flex tw-flex-col'
                    >
                        <div
                            className='tw-mb-8 tw-items-center tw-flex tw-flex-col lg:tw-flex-row'>
                            <div className='tw-flex tw-flex-col tw-mb-8 lg:tw-mb-0 tw-w-full'>
                                <label for="name">
                                    Name
                                </label>
                                <input id="name"
                                    onChange={handleInputChange}
                                    type="first-name"
                                    value={form.name}
                                    name="name" />
                            </div>
                            <div className='tw-flex tw-flex-col tw-w-full'>
                                <label for="email">
                                    Email
                                </label>
                                <input id="email"
                                    value={form.email}
                                    onChange={handleInputChange}
                                    type="email"
                                    name="email" />
                            </div>
                        </div>



                        <div
                            className='tw-flex tw-flex-col tw-mb-8'
                        >
                            <label for="data">
                                Message
                            </label>
                            <textarea id="data"
                                name="data"
                                value={form.data}
                                onChange={handleInputChange}
                                cols="4"
                                rows="4"
                                wrap="hard">
                            </textarea>
                        </div>


                        {showThankYou &&
                            <div className="info tw-mb-8" >
                                <p>
                                    Thank You! We received your submission.
                                </p>
                            </div>

                        }



                        <div
                            className='tw-flex tw-flex-row tw-justify-end tw-mr-4'
                            // fxLayout="row"
                            // fxLayoutAlign="end center"
                            >

                            {/* <!--
                            loading spinner
                            --> */}
                            {loading && <>
                                <div class="lds-roller">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </>}

                            <button type="submit">
                                <span>
                                    SEND
                                </span>
                            </button>

                        </div>


                    </fieldset>
                </form >

            </div >

        </section >
    )
        //   <section fxLayout="column"
        //          class="contact-container"
        //          id="contact_us">

        //   <h4>
        //     {contactUs}
        //   </h4>

        //   <div fxLayout="row"
        //        fxLayout.lt-md="column"
        //        fxLayoutGap="50px">

        //     <div fxFlex="50">
        //       <p>
        //         We have no sales team or layers of management. Connect directly with the people who're going to bring
        //         your ideas to life.
        //       </p>
        //     </div>

        //     <form id="my-formcarry"
        //           #myForm
        //           accept-charset="UTF-8"
        //           fxFlex
        //           fxLayout="column">

        //       <fieldset [disabled]="loading"
        //                 fxLayout="column"
        //                 fxLayoutGap="36px">

        //         <div fxLayout="row"
        //              fxLayoutAlign="start center"
        //              fxLayout.lt-md="column"
        //              fxLayoutAlign.lt-md="start top"
        //              fxLayoutGap="50px">
        //           <div fxLayout="column"
        //                fxLayoutAlign="start start"
        //                fxFlex>
        //             <label for="name">
        //               Name
        //             </label>
        //             <input id="name"
        //                    type="first-name"
        //                    name="name"/>
        //           </div>
        //           <div fxLayout="column"
        //                fxLayoutAlign="start start"
        //                fxFlex>
        //             <label for="email">
        //               Email
        //             </label>
        //             <input id="email"
        //                    type="email"
        //                    name="email"/>
        //           </div>
        //         </div>

        //         <div fxLayout="column"
        //              fxLayoutAlign="start start">
        //           <label for="data">
        //             Message
        //           </label>
        //           <textarea id="data"
        //                     name="data"
        //                     cols="4"
        //                     rows="4"
        //                     value=""
        //                     wrap="hard">
        //           </textarea>
        //         </div>

        //         <div class="info" *ngIf="showThankYou">
        //           <p>
        //             Thank You! We received your submission.
        //           </p>
        //         </div>

        //         <div fxLayout="row"
        //              fxLayoutAlign="end center">

        //           <div class="lds-roller" *ngIf="loading">
        //             <div></div>
        //             <div></div>
        //             <div></div>
        //             <div></div>
        //             <div></div>
        //             <div></div>
        //             <div></div>
        //             <div></div>
        //           </div>

        //           <button type="submit"
        //                   (click) = "clicked()"
        //     [hidden] = "loading" >
        //         <span>
        //             SEND
        //         </span>
        //           </button >

        //         </div >
        //       </fieldset >

        //     </form >

        //   </div >

        // </section >
        ;
}

export default Contactus;
